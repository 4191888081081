class tetra {
	constructor(_x, _y, _z, _s, _c) {
		this.x = _x;
		this.y = _y;
		this.z = _z;
		this.pos = createVector(_x, _y, _z);
		this.s = _s;
		this.color_palette = _c;
		this.r = random(0, TWO_PI);
		this.state_zero_speed = random(-0.01, 0.01);
		this.rotate_state = [PI / 3, PI, (PI * 5) / 3];
		this.state = 0;
		this.is_transitioning = false;
		this.transition_time = 0.2; // sec
	}

	update() {
		if (this.is_transitioning) {
			// transition in progress
			this.r += this.vf;
			// two different stop criteria
			if ( abs(this.target_r - this.r ) < 0.05 ) {
				// transition complete
				this.is_transitioning = false;
				this.state = this.pending_state;
				// DEBUG console.log("hitTIME");
			} else if (frameCount - this.startFrame >= this.transition_frames) {
				this.is_transitioning = false;
				// in this case, rotation is often not complete
				// so I manually set the rotation to the target
				this.r = this.target_r;
				this.state = this.pending_state;
			}
			// limit r in range [0, TWO_PI]
			this.r %= TWO_PI;
			if(this.r < 0) {
				this.r += TWO_PI;
			}
		} else if (this.state == 0) { 
			// free rotate
			this.r += this.state_zero_speed;
			// limit r in range [0, TWO_PI]
			this.r %= TWO_PI;
			if(this.r < 0) {
				this.r += TWO_PI;
			}
			// rise
			this.z += .25;
			this.rndSinPhase = random(200);
			// move back to screen if too far 
			if (this.z > height/2.+this.s*2.5) {
				this.z = -height/2.;
			}
		} else {
			// bounce
			this.y += sin(frameCount * 0.06 + this.rndSinPhase) * 1.5 ;
		}
	}

	change_state(to_state) {
		if (to_state === 0) {
			this.state = 0;
			this.is_transitioning = false;
		} else {
			if (!this.is_transitioning) {
				// start transition
				this.pending_state = to_state;
				this.target_r = this.rotate_state[to_state - 1];
				let diff = this.min_rotate_distance(this.r, this.target_r);
				// this.transition_frames = this.transition_time * frameRate()
				this.transition_frames = 12;
				this.vf = diff / this.transition_frames;
				this.is_transitioning = true;
				this.startFrame = frameCount;
				// print("frm", this.transition_frames);
			}
		}
	}

	// shortest dist from x to y, including rotation direction
	min_rotate_distance(x, y) {
		// let _dist = y - x;
		// let _dist_counter = TWO_PI - abs(_dist);
		// if (_dist > 0) {
		// 	_dist_counter = -_dist_counter;
		// }
		// if (abs(_dist) > abs(_dist_counter)) {
		// 	return _dist_counter;
		// } else {
		// 	return _dist;
		// }
		return abs(y - x) > PI ? TWO_PI - abs(y - x) : y - x;
	}

	display() {
		push();
		noStroke();
		translate(this.x, this.y, this.z);
		rotateZ(this.r);
		// this.display_edges();
		this.display_faces();
		pop();
	}

	display_edges() {
		// vertex1 : (0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s)
		// vertex2 : (-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s)
		// vertex3 : (this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s)
		// vertex4 : (0, 0, (sqrt(3) / 3) * this.s)
		noFill();
		stroke(255, 50);
		strokeWeight(4);
		beginShape(LINES);
		// line 1 (0 - 1)
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		endShape();

		// line 2 (0 - 2)
		beginShape(LINES);
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		endShape();

		// line 3 (1 - 2)
		beginShape(LINES);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		endShape();

		// line 4 (0 - 3) status 1
		beginShape(LINES);
		strokeWeight(3);
		stroke(this.color_palette[0]);
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();

		// line 5 (1 - 3) status 2
		beginShape(LINES);
		strokeWeight(5);
		stroke(this.color_palette[1]);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();

		// line 6 (2 - 3) status 3
		beginShape(LINES);
		strokeWeight(7);
		stroke(this.color_palette[2]);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();
	}

	display_faces() {
		// bottom
		noStroke();
		fill(255, 50);
		beginShape(TRIANGLES);
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		endShape();

		// side 1
		fill(this.color_palette[0]);
		beginShape(TRIANGLES);
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();

		// side 2
		fill(this.color_palette[1]);
		beginShape(TRIANGLES);
		vertex(-this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();

		// side 3
		fill(this.color_palette[2]);
		beginShape(TRIANGLES);
		vertex(0, (-sqrt(3) / 3) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(this.s / 2, (sqrt(3) / 6) * this.s, (-sqrt(3) / 6) * this.s);
		vertex(0, 0, (sqrt(3) / 3) * this.s);
		endShape();
	}
}
